
					@import './src/sass/variables';
					@import './src/sass/mixins';
				













































































































































































































































































































































































.rich-editor {
	position: relative;

	&.editable {
		.format-bar::v-deep {
			&.is-focused {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}

.format-bar::v-deep {
	position: absolute;
	bottom: 100%;
	left: 0;
	transform: translateY(-5px);
	z-index: 10;
	background: $color__dark;
	color: $color__white;
	box-shadow: $box_shadow;
	display: flex;
	height: 40px;
	transition: opacity 0.2s, visibility 0.2s;
	visibility: hidden;
	opacity: 0;
	border-radius: $border_radius;
	overflow: hidden;

	button {
		width: 40px;
		height: 40px;
		background: none;

		&:hover {
			background: rgba($color__white, 0.1);
		}

		&.is-active {
			background: $color__link;
		}
	}
}

.editor::v-deep {
	.ProseMirror {
		background: $color__white;
		font-size: 1.25rem;
		width: 100%;
		border: none;
		box-shadow: $box_shadow__input;
		border-radius: $border_radius;
		padding: 0.5rem 1rem;
		margin-bottom: $default_padding / 2;
		line-height: 1em;
		outline: none !important;
		transition: box-shadow 0.2s ease-out;
	}

	.ProseMirror-focused {
		@include inner-border(1px, $color__light-blue);
	}

	.selectedCell {
		background-color: rgba($color: $color__light_blue, $alpha: 0.2);
	}

	h2 {
		font-size: 1.3em;
		margin-top: 1.5em;
		margin-bottom: 0.25em;
	}

	h3 {
		font-size: 1em;
		line-height: $line__height;
		margin: 0;
	}

	li {
		margin-bottom: 0.6em;
	}

	a {
		pointer-events: none;
		cursor: text;
	}

	:first-child {
		margin-top: 0;
	}

	:last-child {
		margin-bottom: 0;
	}

	p.is-editor-empty:first-child::before {
		content: attr(data-empty-text);
		float: left;
		color: #aaa;
		pointer-events: none;
		height: 0;
		font-style: italic;
	}

	table p {
		margin: 0;
	}
}
